#cardNumberSecureField_container,
#expirationDateSecureField_container,
#securityCodeSecureField_container,
.cardholderName_container {
  position: relative;
  width: 100%;
  height: 34px;
  padding: 0 10px;
  overflow: hidden;
}

#walletBrick_container {
  width: 100%;
}

#cardNumberSecureField_container::before,
#expirationDateSecureField_container::before,
#securityCodeSecureField_container::before,
.cardholderName_container::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  animation: fadeIn 1.5s forwards;
  content: "";
  z-index: 98;
}

#cardNumberSecureField_container::after,
#expirationDateSecureField_container::after,
#securityCodeSecureField_container::after,
.cardholderName_container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(200, 200, 200, 0) 0,
    rgba(200, 200, 200, 0.2) 20%,
    rgba(200, 200, 200, 0.5) 60%,
    rgba(200, 200, 200, 0)
  );
  animation: shimmer 2s forwards;
  content: "";
  z-index: 99;
}

@keyframes fadeIn {
  0%,
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  51% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
