@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
}

html {
  background-color: #fff;
}

input::placeholder {
  color: #828282;
}
